<template>
  <div>
    <div :class="$style.home">
      <div :class="$style.left">
        <div :class="[$style.item, type === 'base' ? $style.itemActive : null]" @click="typeChange('base')">
          <i class="ri-file-list-2-line mr-8" />
          基本信息
        </div>
        <div :class="[$style.item, type === 'safe' ? $style.itemActive : null]" @click="typeChange('safe')">
          <i class="ri-shield-flash-line mr-8" />
          安全设置
        </div>
        <div :class="[$style.item, type === 'bind' ? $style.itemActive : null]" @click="typeChange('bind')">
          <i class="ri-link mr-8" />
          账号绑定
        </div>
        <div :class="$style.line" />
        <div :class="[$style.item, type === 'author' ? $style.itemActive : null]" @click="typeChange('author')">
          <i class="ri-account-circle-line mr-8" />
          作者信息
        </div>
      </div>
      <div :class="$style.right">
        <div v-if="type === 'author'" class="max600">
          <p :class="$style.title">作者信息</p>
          <div class="layout">
            <div class="label">
              <span class="required">*</span>
              作者姓名
            </div>
            <div class="value">
              <BjInput v-model="author.real_name" placeholder="请输入作者姓名" />
              <p class="tip mb-20 mt-10">请输入作者姓名，作者姓名会显示到本作者创作的内容页面。</p>
            </div>
          </div>
          <div class="layout">
            <div class="label">
              <span class="required">*</span>
              作者头像
            </div>
            <div class="value">
              <uploadAvatar :src="author.avatar" @complete="avatarChange" />
              <p class="tip mt-10 mb-20">作者头像建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
            </div>
          </div>
          <div class="layout">
            <div class="label">主页封面</div>
            <div class="value">
              <uploadAvatar :src="author.page_cover" @complete="coverChange" />
              <p class="tip mt-10 mb-20">主页封面建议尺寸780px*440px；大小不超过2M；格式支持JPG、PNG。</p>
            </div>
          </div>
          <div class="layout">
            <div class="label">个性签名</div>
            <div class="value">
              <a-textarea
                v-model="author.personality"
                placeholder="请输入作者的个性签名信息..."
                :auto-size="{ minRows: 4, maxRows: 6 }"
              />
            </div>
          </div>
          <div class="layout">
            <div class="label" />
            <div class="value">
              <BjButton
                v-permission="'system.account.info-author.edit'"
                class="mt-20"
                type="primary"
                @click="save('author')"
                >立即保存</BjButton
              >
            </div>
          </div>
        </div>

        <div v-if="type === 'safe'">
          <p :class="$style.title">安全设置</p>
          <div>
            <p :class="$style.stitle">
              账户密码
              <span class="primary pull-right cursor" @click="visible.password = true">修改</span>
            </p>
            <p :class="$style.desc">请定期修改账户密码，以保障账户安全！</p>
          </div>
          <div :class="$style.line" />
          <div>
            <p :class="$style.stitle">
              绑定邮箱
              <span class="primary pull-right cursor" @click="onEditEmail(!!info.info.email)">{{
                info.info.email ? '修改' : '绑定'
              }}</span>
            </p>
            <p v-if="info.info.email">
              <span :class="$style.desc">已绑定邮箱：</span>
              <span class="primary">{{ info.info.email }}</span>
              <span class="tag ml-10">主账号</span>
            </p>
            <p v-else :class="$style.desc">未绑定邮箱，绑定后可使用邮箱登录。</p>
          </div>
          <div :class="$style.line" />
          <div>
            <p :class="$style.stitle">
              绑定手机
              <span class="primary pull-right cursor" @click="onEditPhone(!!info.info.telephone)">{{
                info.info.telephone ? '修改' : '绑定'
              }}</span>
            </p>
            <p v-if="info.info.telephone">
              <span :class="$style.desc">已绑定手机：</span>
              <span class="primary">{{ info.info.telephone }}</span>
              <span class="tag ml-10">次账号</span>
            </p>
            <p v-else :class="$style.desc">未绑定手机，绑定后可使用手机登录。</p>
          </div>
          <div :class="$style.line" />
        </div>

        <div v-if="type === 'bind'">
          <p :class="$style.title">账号绑定</p>
          <div :class="$style.bindBox">
            <div :class="$style.head">
              <img :src="info.third_party.wx.avatar" alt="" />
            </div>
            <div :class="$style.content">
              <p :class="$style.contentTitle">微信</p>
              <p v-if="!info.third_party.wx.is_bind">未绑定微信，绑定后可微信扫码登录。</p>
              <p v-else>已绑定微信：{{ info.third_party.wx.nickname }}</p>
            </div>
            <div :class="$style.btn">
              <BjLink v-permission="'system.account.info-bind.edit'" type="primary" @click="onBind()">{{
                info.third_party.wx.is_bind ? '更换' : '绑定'
              }}</BjLink>
            </div>
          </div>
        </div>

        <div v-if="type === 'base'" class="max600">
          <p :class="$style.title">基本信息</p>
          <div class="layout">
            <div class="label">登录账号</div>
            <div class="value" :class="$style.user" span="20">
              <span :class="$style.default">{{ info.info.account }}</span>
              <div class="tag">{{ info.info.role_name }}</div>
            </div>
          </div>
          <div class="mb-20 layout">
            <div class="label">
              <span class="required">*</span>
              账号头像
            </div>
            <div span="20 value">
              <uploadAvatar :src="info.info.avatar" @complete="baseAvatarChange" />
              <p class="tip mt-10 mb-0">账号头像建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
            </div>
          </div>
          <div class="mb-20 layout">
            <div class="label">
              <span class="required">*</span>
              账号姓名
            </div>
            <div class="value">
              <BjInput v-model="info.info.real_name" allow-clear placeholder="请输入账号姓名" />
            </div>
          </div>
          <div class="layout">
            <div class="label">描述信息</div>
            <div class="value">
              <a-textarea
                v-model="info.info.describe"
                placeholder="这里是描述信息..."
                :auto-size="{ minRows: 4, maxRows: 6 }"
              />
            </div>
          </div>
          <div class="layout">
            <div class="label" />
            <div class="value">
              <BjButton
                v-permission="'system.account.info-base.edit'"
                class="mt-20"
                type="primary"
                @click="save('base')"
                >立即保存</BjButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <bj-modal
      :title="info.third_party.wx.is_bind ? '更换微信' : '绑定微信'"
      :visible="visible.bind"
      :body-style="{ height: '400px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
      @ok="bindOk"
      @cancel="bindCancel"
    >
      <div :class="$style.wx">
        <a-popover placement="bottomLeft" title="说明">
          <template slot="content">
            <p>1、首次扫码绑定可能会需要先关注微信公众号。</p>
            <p>
              2、如果扫码之后页面没有跳转动作，请点击
              <span class="primary cursor" @click="getBind()">刷新二维码</span>
              后重新扫码。
            </p>
          </template>
          <i class="ri-question-line" :class="$style.popover" />
        </a-popover>
        <div :class="$style.wxbox">
          <img :src="bind.url" alt="" />
          <div v-if="bind.state === true || bind.state === false" :class="$style.state">
            <i v-if="bind.state === true" class="ri-check-line" />
            <i v-if="bind.state === false" class="ri-close-line" />
          </div>
          <div v-if="bind.state === 'waiting'" :class="$style.state">
            <p :class="$style.txt">二维码已过期</p>
            <div :class="$style.icon" @click="refreshCode()">
              <i class="ri-restart-line" />
            </div>
          </div>
        </div>
        <p v-if="bind.state === null">
          <i class="ri-qr-scan-2-line" />
          请使用微信扫码绑定
        </p>
        <p v-if="bind.state === true">
          <i class="ri-emotion-line" />
          微信已经绑定成功…
        </p>
        <p v-if="bind.state === false">
          <i class="ri-emotion-unhappy-line" />
          {{ bind.error }}
        </p>
      </div>
    </bj-modal>
    <bj-modal
      title="修改密码"
      :visible="visible.password"
      :body-style="{ height: '400px' }"
      :class="$style.modalPass"
      @ok="passwordOk"
      @cancel="passwordCancel"
    >
      <BjForm>
        <BjInput
          v-model="modalPassword.original_password"
          v-bind="layout"
          rules="required"
          label="原始密码"
          label-align="left"
          placeholder="请输入你账号的原始密码"
          type="password"
          @keydown.native="keydown($event)"
          @keyup="modalPassword.original_password = modalPassword.original_password.replace(/[\u4e00-\u9fa5]/g, '')"
        />
        <BjInput
          v-model="modalPassword.password"
          v-bind="layout"
          rules="required"
          label="新的密码"
          label-align="left"
          autocomplete="new-password"
          :type="passwordType"
          placeholder="请输入你需要设置的新的密码"
          @keydown.native="keydown($event)"
          @keyup="modalPassword.password = modalPassword.password.replace(/[\u4e00-\u9fa5]/g, '')"
        >
          <a-tooltip slot="suffix" title="查看密码">
            <span @click="passwordChange()"
              ><i
                :style="passwordType === 'password' ? 'color: #bbb' : ''"
                class="ri-eye-line primary"
                :class="$style.icon"
            /></span>
          </a-tooltip>
        </BjInput>
      </BjForm>
    </bj-modal>
    <bj-modal
      :title="modalTitle"
      :visible="visible.email"
      :body-style="{ height: '400px' }"
      :class="$style.modalPass"
      @ok="emailOk"
      @cancel="emailCancel"
    >
      <BjForm>
        <BjInput
          v-model="modalEmail.account"
          v-bind="layout"
          rules="required"
          label="邮箱地址"
          tooltips
          label-align="left"
          placeholder="请输入你需要绑定的邮箱地址"
        />
        <BjInput
          v-model="modalEmail.code"
          v-bind="layout"
          tooltips
          rules="required"
          label="验证码"
          label-align="left"
          placeholder="请输入你邮箱收到的验证码"
        >
          <span v-if="!finish" slot="suffix" class="tag cursor" @click="getCode()">
            {{ time === 0 ? '没收到？重新发送' : '获取验证码' }}
          </span>
          <span v-else slot="suffix" class="tag">{{ time }}秒后可重新获取</span>
        </BjInput>
      </BjForm>
    </bj-modal>
    <bj-modal
      :title="modalTitle"
      :visible="visible.phone"
      :body-style="{ height: '400px' }"
      :class="$style.modalPass"
      @ok="phoneOk"
      @cancel="phoneCancel"
    >
      <BjForm>
        <BjInput
          v-model="modalPhone.account"
          v-bind="layout"
          rules="required"
          tooltips
          label="手机号"
          label-align="left"
          placeholder="请输入你需要绑定的手机号码"
        />
        <BjInput
          v-model="modalPhone.code"
          v-bind="layout"
          tooltips
          rules="required"
          label="验证码"
          label-align="left"
          placeholder="请输入你邮箱收到的验证码"
        >
          <span v-if="!finish" slot="suffix" class="tag cursor" @click="getPhoneCode()">{{
            time === 0 ? '没收到？重新发送' : '获取验证码'
          }}</span>
          <span v-else slot="suffix" class="tag">{{ time }}秒后可重新获取</span>
        </BjInput>
      </BjForm>
    </bj-modal>
    <validateModal :visible.sync="visible.validate" :params="params" @submit="validate" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import uploadAvatar from '@/components/uploadAvatar'
import validateModal from '@/components/validateModal'
import { systemInfoService } from '@/service'

const service = new systemInfoService()

export default {
  name: 'Home',
  components: {
    validateModal,
    uploadAvatar,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      // base 基本设置 安全 safe 绑定 bind 作者 author
      type: 'base',
      visible: {
        bind: false,
        password: false,
        email: false,
        validate: false,
        phone: false,
      },
      info: {}, //大部分显示数据
      author: {}, // 作者信息
      bind: {
        url: null,
        error: null,
        state: null, // 空正在轮训 true成功  false失败
      }, // 账号绑定信息
      modalPassword: {
        original_password: null,
        password: null,
      },
      params: {},
      passwordType: 'password',
      modalTitle: '',
      modalEmail: {
        account_type: 'email',
        account: null,
        code: null,
      },
      time: 60,
      finish: false,
      valid_key: null,
      modalPhone: {
        account_type: 'telephone',
        account: null,
        code: null,
      },
      timerNum: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getInfo()
    this.getAuthor()
    if (this.$route.query.page === 'author') {
      this.type = 'author'
    }
  },
  methods: {
    ...mapActions(['refreshToken']),
    async getInfo() {
      const { data } = await service.getInfo()
      this.info = data
    },
    async getAuthor() {
      const { data } = await service.getAuthor()
      this.author = data
    },
    async getBind() {
      this.bind.error = null
      this.bind.state = null
      this.timerNum = 0
      const { data } = await service.getBind({
        platform_type: 'wx',
      })
      this.bind.url = data.url
    },
    async getBindCheck() {
      this.timerNum += 1
      const { data } = await service.getBindCheck({
        platform_type: 'wx',
      })
      if (data.status === 'done') {
        this.bind.state = true
      }
      if (data.status === 'error') {
        this.bind.state = false
        this.bind.error = data.message
      }
      if (data.status === 'waiting') {
        if (this.timerNum > 100) {
          this.bind.state = 'waiting'
          clearInterval(this.timer)
          return
        }
        this.timer = setTimeout(() => {
          this.getBindCheck()
        }, 3000)
      }
    },
    typeChange(_type) {
      this.type = _type
    },
    onBind() {
      this.visible.bind = true
      this.getBind()
      this.getBindCheck()
    },
    bindOk() {
      this.getBind()
      this.getInfo()
      clearInterval(this.timer)
      this.bind = {
        url: null,
        error: null,
        state: null,
      }
      this.visible.bind = false
    },
    bindCancel() {
      this.getBind()
      this.getInfo()
      clearInterval(this.timer)
      this.bind = {
        url: null,
        error: null,
        state: null,
      }
      this.visible.bind = false
    },
    refreshCode() {
      this.bind.error = null
      this.bind.state = null
      this.timerNum = 0
      this.getBind()
      this.getBindCheck()
    },
    async save(_type) {
      switch (_type) {
        case 'author':
          try {
            await service.saveAuthor({
              ...this.author,
            })
            this.$message.success('保存成功')
          } catch (e) {}
          break
        case 'base':
          try {
            await service.saveBase({
              real_name: this.info.info.real_name,
              avatar: this.info.info.avatar,
              describe: this.info.info.describe,
            })
            this.refreshToken()
            this.$message.success('保存成功')
          } catch (e) {}
          break
      }
    },
    validate(e) {
      if (e.type) {
        this.visible[e.type] = true
        this.valid_key = e.valid_key
      }
    },
    async passwordOk() {
      try {
        if (!this.modalPassword.original_password) {
          this.$message.warning('请输入原始密码')
          return
        }
        if (!this.modalPassword.password) {
          this.$message.warning('请输入新的密码')
          return
        }
        if (this.modalPassword.password === this.modalPassword.original_password) {
          this.$message.warning('新密码与原密码相同')
          return
        }
        await service.password(this.modalPassword)
        this.visible.password = false
        this.$message.success('修改成功')
      } catch (e) {}
    },
    passwordCancel() {
      this.visible.password = false
      this.modalPassword = {
        original_password: null,
        password: null,
      }
    },
    passwordChange() {
      this.passwordType = this.passwordType === 'text' ? 'password' : 'text'
    },
    onEditEmail(type) {
      this.modalTitle = type ? '修改邮箱' : '绑定邮箱'
      if (type) {
        this.visible.validate = true
        this.params = {
          type: 'email',
        }
      } else {
        this.visible.email = true
      }
    },
    onEditPhone(type) {
      this.modalTitle = type ? '修改手机' : '绑定手机'
      if (type) {
        this.visible.validate = true
        this.params = {
          type: 'phone',
        }
      } else {
        this.visible.phone = true
      }
    },
    async getCode() {
      if (!this.modalEmail.account) {
        this.$message.warning('请输入邮箱')
        return
      }
      try {
        await service.getValidCode({
          template: 'bind_account',
          username: this.modalEmail.account,
        })
        this.finish = true
        this.time = 60
        setInterval(() => {
          if (this.time === 0) {
            this.finish = false
          } else {
            this.time--
          }
        }, 1000)
        this.$message.success('带有验证码的信息已经通过你填写的邮箱或手机号码发送给你，请注意查收。')
      } catch (e) {}
    },
    async getPhoneCode() {
      if (!this.modalPhone.account) {
        this.$message.warning('请输入手机')
        return
      }
      try {
        await service.getValidCode({
          template: 'bind_account',
          username: this.modalPhone.account,
        })
        this.finish = true
        this.time = 60
        setInterval(() => {
          if (this.time === 0) {
            this.finish = false
          } else {
            this.time--
          }
        }, 1000)
        this.$message.success('带有验证码的信息已经通过你填写的邮箱或手机号码发送给你，请注意查收。')
      } catch (e) {}
    },
    async emailOk() {
      try {
        if (!this.info.info.email) {
          await service.bind(this.modalEmail)
        } else {
          await service.change({ ...this.modalEmail, valid_key: this.valid_key })
        }
        this.visible.email = false
        this.$message.success('操作成功')
        this.getInfo()
        this.refreshToken()
      } catch (e) {}
    },
    emailCancel() {
      this.visible.email = false
      this.modalEmail = {
        account_type: 'email',
        account: null,
        code: null,
      }
      this.time = 60
    },
    async phoneOk() {
      try {
        if (!this.info.info.telephone) {
          await service.bind(this.modalPhone)
        } else {
          await service.change({ ...this.modalPhone, valid_key: this.valid_key })
        }
        this.visible.phone = false
        this.$message.success('操作成功')
        this.getInfo()
        this.refreshToken()
      } catch (e) {}
    },
    phoneCancel() {
      this.visible.phone = false
      this.modalPhone = {
        account_type: 'phone',
        account: null,
        code: null,
      }
      this.time = 60
    },
    avatarChange(e) {
      this.author.avatar = e
    },
    coverChange(e) {
      this.author.page_cover = e
    },
    baseAvatarChange(e) {
      this.info.info.avatar = e
    },
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false
      }
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;
  min-height: calc(100vh - 152px);
  padding: 20px;
  background: #fff;

  .left {
    width: 200px;
    height: 100%;
    padding: 0 20px 0 0;

    .item {
      width: 180px;
      height: 44px;
      margin-bottom: 2px;
      padding: 0 12px;
      color: #000;
      font-size: 14px;
      line-height: 44px;
      border-radius: 6px;
      cursor: pointer;

      i {
        font-size: 20px;
        vertical-align: -4px;
      }
    }

    .item:hover {
      background: #fafafa;
    }

    .item-active {
      color: @primary-color;
      background: #fafafa;
    }

    .line {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      border-bottom: 1px solid #eee;
    }
  }

  .right {
    flex: 1;
    padding: 0 20px;
    border-left: 1px solid #eee;

    .title {
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;
    }

    .box {
      display: flex;
      margin-bottom: 20px;

      .img-box {
        position: relative;
        width: 70px;
        height: 70px;
        margin-left: 20px;
        border: 2px dashed #eee;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 100%;
          max-height: 100%;
          transform: translate(-50%, -50%);
        }
      }

      .add-box {
        width: 70px;
        height: 70px;
        line-height: 68px;
        text-align: center;
        border: 2px dashed #eee;
        cursor: pointer;

        i {
          color: #5c5c5c;
          font-size: 30px;
        }
      }
    }

    .bind-box {
      display: flex;
      border-bottom: 1px solid #eee;

      .head {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-right: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }

      .content {
        flex: 1;

        &-title {
          margin-bottom: 10px;
          color: #000;
        }
      }

      .btn {
        width: 50px;
      }
    }

    :global {
      .ant-col-4 {
        height: 40px;
        color: #000;
        line-height: 40px;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .default {
      color: #000;
      font-size: 14px;
    }

    .user {
      line-height: 40px;

      & > div {
        margin-left: 10px;
      }
    }

    .stitle {
      margin-bottom: 10px;
      color: #000;
      font-size: 14px;
    }

    .line {
      width: 100%;
      margin: 20px 0;
      border-bottom: 1px solid #eee;
    }

    .desc {
      color: #5c5c5c;
      font-size: 14px;
    }
  }
}

.wx {
  position: relative;
  width: 100%;
  padding-top: 65px;
  text-align: center;

  .wxbox {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 170px;
    /* stylelint-disable-next-line no-descending-specificity */
    img {
      width: 100%;
      height: 100%;
    }

    .state {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 170px;
      background: rgba(0, 0, 0, 0.68);
      /* stylelint-disable-next-line no-descending-specificity */
      i {
        color: #fff;
        font-size: 50px;
      }

      .icon {
        width: 32px;
        height: 32px;
        margin: 0 auto;
        line-height: 32px;
        text-align: center;
        background-color: #fff;
        border-radius: 100%;

        i {
          color: @primary-color;
          font-size: 20px;
        }
      }

      .txt {
        margin-top: 53px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 14px;
        line-height: 1;
        text-align: center;
      }
    }
  }

  & > p {
    margin: 20px 0;
    font-size: 12px;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      color: #000;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .popover {
    position: absolute;
    top: 0;
    right: 0;
    color: #5c5c5c;
    font-size: 20px;
  }
}

.modal-pass {
  /* stylelint-disable-next-line no-descending-specificity */
  .stitle {
    color: #000;
    font-size: 14px;
  }
}
</style>
